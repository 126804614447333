@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=fallback');
@import 'tailwindcss/base';
@import 'tailwindcss/components';
/* Additional styles */
@import 'additional-styles/utility-patterns.css';
@import 'additional-styles/flatpickr.css';

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  #root {
    /* Full height of screen and flex so that we can push footer */
    /* to the bottom even if content is shorter than screen */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  /* Prevent child elements from shrinking when content */
  /* is taller than the screen (quirk of having flex parent) */
  #root > * {
    flex-shrink: 0;
  }

  /*h1, h2, h3, h4, h5, h6, p, span, a {*/
  /*  color: theme('colors.gray.600'); !* Adjust this to your desired color *!*/
  /*}*/

}

@layer components {
  .dark-text-all h1, .dark-text-all h2, .dark-text-all h3, .dark-text-all h4, .dark-text-all h5, .dark-text-all h6, .dark-text-all p, .dark-text-all strong, .dark-text-all span, .dark-text-all a {
    @apply dark:text-dark;
  }
}

@layer utilities {
  .section.bg-white + .section.bg-white {
    border-top: 1px solid #f0f0f0;
  }
  .pattern-dots {
    background-image: radial-gradient(currentColor 2px, transparent 2px);
    background-size: calc(10 * 2px) calc(10 * 2px);
  }
}


body {
  @apply bg-white dark:bg-dark text-gray-600 dark:text-dark;
}

@keyframes flash {
  0% {
    background-color: yellow;
  }
  100% {
    background-color: white;
  }
}

.flash {
  animation: flash 0.5s ease-in-out;
}

.markdown-body ul {
  list-style-type: none;
  padding-left: 0;
}

.markdown-body li {
  margin-bottom: 0.75rem;
}

.markdown-body li span {
  line-height: 1.5;
}

.markdown-body pre {
  margin: 1em 0;
}

.markdown-body code {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, monospace;
}
